import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";

const staticHeader = (
  doc,
  finalY,
  templateId,
  showDate,
  shopsList,
  shopId,
  competentPerson,
  finalData
) => {
  // Define the static header content
  const headerData = [
    [
      "Place of Inspection",
      `${finalData?.[0]?.client_name || ""},\n ${
        finalData?.[0]?.client_address || ""
      }`,
    ],
    ["Date Of Inspection", showDate || ""],
    ...(shopId
      ? [["Shop/Plant", shopsList.find((el) => el.id === shopId)?.name || ""]]
      : []),

    [
      "Section Rule",
      templateId == 13
        ? "Section 28/29 & Rule 62/64 of MFR 1963."
        : "Section 31 & Rule 65 of MFR 1963",
    ],
  ];

  // Use autoTable to render the header
  doc.autoTable({
    body: headerData,
    startY: finalY + 10,
    margin: { top: 5, left: 10, right: 10 },
    theme: "plain",
    styles: {
      fontSize: 10,
      fontStyle: "normal",
      cellPadding: 3,
      textColor: [0, 0, 0],
    },
    columnStyles: {
      0: { fontStyle: "bold", cellWidth: 80 },
      1: { cellWidth: 240 },
    },
  });

  // Optionally return the final Y position to adjust for further content
  return doc.lastAutoTable.finalY;
};

export const commanTable = (finalData, columns) => {
  // Check if any column has id "thicknessTest"
  const hasThicknessTest = columns.some(
    (column) => column.id === "thicknessTest"
  );

  // Generate the first row of headers
  const firstRow = columns.map((column) => {
    if (column.id === "thicknessTest") {
      return {
        content: "Thickness Observed in mm",
        colSpan: 2,
      };
    } else if (column.id === "id" && hasThicknessTest) {
      return {
        content: column.label,
        rowSpan: 2,
      };
    } else {
      return {
        content: column.label,
        rowSpan: hasThicknessTest ? 2 : 1,
        styles: column.id != "id" ? { minCellWidth: 30 } : {},
      };
    }
  });

  // Generate the second row of headers for "Shell" and "Dish"
  const secondRow = hasThicknessTest
    ? [
        { content: "Shell", styles: { minCellWidth: 25 } },
        { content: "Dish", styles: { minCellWidth: 25 } },
      ]
    : [];

  const tableHeader = [firstRow];
  if (secondRow.length > 0) {
    tableHeader.push(secondRow);
  }

  // Generate tableData
  const tableData = finalData
    ?.sort((a, b) => a.id - b.id)
    ?.map((row, index) => {
      return columns.map((column, columnIndex) => {
        let value;

        if (
          column.id === "typeIdentificationNumber" ||
          column.id === "distinctiveNumber" ||
          column.id === "ultrasonicTest"
        ) {
          value = row[column.id] || "";
        } else if (columnIndex === 1) {
          const value1 = row["equipment_name"] || "";
          const value2 = row[columns[1].id] || "";
          value = `${value1}, ${value2}`;
        } else {
          if (
            column.id === "certificateValidity" ||
            column.id === "inspectionDate" ||
            column.id === "hydraulicTestDate" ||
            column.id === "utrasonicNextDueDateCalculate" ||
            column.id === "hydraulicNextDueDateCalculate" ||
            column.id === "utrasonicTestDate"
          ) {
            const newDate = moment(row[column.id]).format("DD-MM-YYYY");
            value = row[column.id] ? newDate : null;
          } else {
            value = row[column.id];
          }
          if (column.id === "id") {
            value = index + 1;
          }
        }

        if (typeof value === "string") {
          value = `${value.replace(/\s+/g, " ").replace(/\\n/g, "\n").trim()}`;
        }

        return value || "";
      });
    });

  return { tableHeader, tableData };
};

const drawText = (
  doc,
  text,
  position,
  y,
  { size = 12, fontStyle = "normal", fontFamily = "helvetica" } = {}
) => {
  doc.setFont(fontFamily, fontStyle);
  doc.setFontSize(size);

  const lines = text.split("\n"); // Split text by newline
  let updatedY = y;

  lines.forEach((line) => {
    // Calculate text alignment based on position
    let x = 10;
    if (position === "center") {
      x = doc.internal.pageSize.getWidth() / 2;
      doc.text(line, x, updatedY, { align: "center" });
    } else if (position === "right") {
      x = doc.internal.pageSize.getWidth() - 10;
      doc.text(line, x, updatedY, { align: "right" });
    } else if (position?.includes(",")) {
      const [alignment, xPosition] = position?.split(",");
      x =
        alignment == "left"
          ? xPosition
          : doc.internal.pageSize.getWidth() - xPosition;
      doc.text(line, x, updatedY, { align: alignment });
    } else {
      doc.text(line, x, updatedY, { align: "left" });
    }

    // Update Y-coordinate for the next line
    const textHeight = doc.getTextDimensions(line).h;
    updatedY += textHeight + 1;
  });

  // Return the updated Y-coordinate with padding
  return updatedY;
};

export const generatePDF1 = async ({
  finalData,
  columns,
  templateId,
  showDate,
  shopsList,
  shopId,
  competentPerson,
  companyList,
  selectedIds,
  electronicSign,
}) => {
  const doc = new jsPDF({
    orientation: "l",
    compress: true,
  });

  const totalWidth = doc.internal.pageSize.getWidth();
  const totalHeight = doc.internal.pageSize.getHeight();
  const margin = 5;
  const contentWidth = totalWidth - margin * 2;
  const widthFor0And2 = 7;
  const remainingWidth = contentWidth - 2 * widthFor0And2;
  let latestY = margin + 5;

  // if (doc.getNumberOfPages() === doc.internal.getCurrentPageInfo().pageNumber) {
  //   doc.rect(margin, margin, totalWidth - margin * 2, totalHeight - margin);
  // }

  // Retrieve selected company details only once
  const selectedCompany = companyList?.find(
    (company) => company.id === selectedIds.companyID
  );

  if (selectedCompany) {
    latestY = drawText(doc, selectedCompany?.label, "center", latestY + 2, {
      size: 14,
      fontStyle: "bold",
      fontFamily: "times",
    });

    latestY = drawText(doc, selectedCompany?.address, "center", latestY, {
      size: 11,
      fontStyle: "normal",
      fontFamily: "times",
    });

    doc.line(margin, latestY + 1, totalWidth - margin, latestY + 1);
  }

  latestY = drawText(
    doc,
    `${selectedIds?.templateId == 12 ? `SERVICE REPORT` : `Service Report`}`,
    "center",
    latestY + 10,
    {
      size: 14,
      fontStyle: "bold",
      fontFamily: "times",
    }
  );

  const inspectionDetails =
    selectedIds?.templateId == 12
      ? "TESTING OF PRESSURE VESSELS / PRESSURE PLANTS"
      : "INSPECTION DETAILS OF TESTING OF HOIST, LIFT & LIFTING MACHINERY";

  latestY = drawText(doc, inspectionDetails, "center", latestY, {
    size: 11,
    fontStyle: "normal",
    fontFamily: "times",
  });

  // Add header and footer
  latestY = await staticHeader(
    doc,
    latestY,
    templateId,
    showDate,
    shopsList,
    shopId,
    competentPerson,
    finalData
  );

  // Extract table header and data
  const { tableHeader, tableData } = commanTable(finalData, columns);

  doc.autoTable({
    head: tableHeader,
    body: tableData,
    startY: latestY + 5,
    margin: { top: 5, left: margin + 2, right: margin + 2, bottom: 5 },
    theme: "grid",
    styles: {
      fontSize: 9,
      cellPadding: 2,
      textColor: [0, 0, 0],
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
    },
    headStyles: {
      textColor: [0, 0, 0],
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
      fillColor: false,
    },
  });

  if (selectedIds?.templateId == 12) {
    doc.autoTable({
      head: [["Test", "Date of Testing", "Qty"]],
      body: [
        ["Ultrasonic", "", ""],
        ["Hydraulic", "", ""],
      ],
      startY: doc.lastAutoTable?.finalY + 20,
      theme: "grid",
      styles: {
        fontSize: 10,
        fontStyle: "bold",
        cellPadding: { top: 5, bottom: 5 },
        textColor: [0, 0, 0],
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        halign: "center",
        valign: "middle",
      },
      headStyles: {
        fontSize: 11,
        fontStyle: "bold",
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        halign: "center",
        valign: "middle",
      },
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 40 },
        2: { cellWidth: 40 },
      },
    });
  }

  let previousY = doc.lastAutoTable?.finalY + 20;

  latestY = drawText(
    doc,
    `Signed by Authorised Person`,
    "right,20",
    previousY,
    {
      size: 11,
      fontStyle: "bold",
      fontFamily: "times",
    }
  );

  latestY = drawText(
    doc,
    `Signed by Projects Authorised Person\n ${finalData?.[0]?.client_name}\n ${finalData?.[0]?.client_address}`,
    "left,20",
    previousY,
    {
      size: 11,
      fontStyle: "bold",
      fontFamily: "times",
    }
  );

  doc.save("ServiceReport.pdf");
};
